<template>
  <div class="chat-hover">
    <p>Overdue by {{ time }}</p>
    <hr />
    <p>Last Response by {{ lastResponseText }}</p>
    <p>{{ lastMessageUpdateTimeString }}</p>
    <!-- <hr />
    <p>Created 30 minute ago</p>
    <p>1 Jan 2025 15:00:00</p> -->
  </div>
</template>

<script>
export default {
  name: "ChatDueToolTip",
  props: {
    time: {
      required: true,
      type: String
    },
    lastMessageUpdateTime: {
      required: false,
      type: String,
      default: null
    }
  },
  data() {
    return {
      currentDateTime: this.getCurrentDateTime(),
      optionDateTime: {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false
      },
      tooltipTop: 0,
      tooltipLeft: 0
    };
  },
  computed: {
    lastResponseText() {
      if (!this.lastMessageUpdateTime) {
        return "No response time available";
      }

      const lastUpdate = new Date(this.lastMessageUpdateTime);
      const now = new Date(this.currentDateTime);
      const diffInSeconds = this.calculateDiffInSeconds(now, lastUpdate);

      return this.diffInSecondsText(diffInSeconds);
    },
    lastMessageUpdateTimeString() {
      if (!this.lastMessageUpdateTime) {
        return "";
      }
      const date = new Date(this.lastMessageUpdateTime);

      return date.toLocaleString("en-GB", this.optionDateTime); // ใช้รูปแบบอังกฤษ (GB)
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.currentDateTime = this.getCurrentDateTime();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getCurrentDateTime() {
      const now = new Date();
      return now;
    },
    calculateDiffInSeconds(now, lastUpdate) {
      return Math.floor((now - lastUpdate) / 1000);
    },
    diffInSecondsText(diffInSeconds) {
      if (diffInSeconds < 60) {
        return "Just now";
      } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else if (diffInSeconds < 604800) {
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} day${days > 1 ? "s" : ""} ago`;
      } else if (diffInSeconds < 2592000) {
        const weeks = Math.floor(diffInSeconds / 604800);
        return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
      } else if (diffInSeconds < 31536000) {
        const months = Math.floor(diffInSeconds / 2592000);
        return `${months} month${months > 1 ? "s" : ""} ago`;
      } else {
        const years = Math.floor(diffInSeconds / 31536000);
        return `${years} year${years > 1 ? "s" : ""} ago`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.chat-hover {
  text-align: right;

  hr {
    background-color: white;
    margin-bottom: 0;
  }
  p {
    font-size: small;
    margin-bottom: 0;
  }
}
</style>
