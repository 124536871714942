<template>
  <div class="text-right text-con-mode">
    <div
      v-if="adminImageUrl"
      class="rounded-circle user_img_display img-admin-size mb-1"
      v-bind:style="{
        backgroundImage: 'url(' + adminImageUrl + ')'
      }"
      :id="`tooltip-admin-${indexKey}-${firstname}`"
    ></div>
    <div
      v-else
      class="rounded-circle  img-admin-size mb-1 ml-1"
      :id="`tooltip-admin-${indexKey}-${firstname}`"
      :class="[primaryColor ? 'img-admin' : 'img-admin-secondary']"
    >
      <span>{{ formatName(firstname) }}</span>
    </div>
    <b-tooltip
      :target="`tooltip-admin-${indexKey}-${firstname}`"
      triggers="hover"
      variant="light"
      placement="bottomright"
      :no-fade="true"
    >
      {{ `${firstname} ${lastname}` }}
    </b-tooltip>
    <div
      v-if="totalAdminMention > 0"
      class="rounded-pill img-admin mention-count  mb-1 ml-1"
      :id="`tooltip-mention-${indexKey}-${firstname}`"
      @click.stop="$emit('show-list-mention')"
    >
      <span>+{{ totalAdminMention }}</span>
    </div>
    <b-tooltip
      :target="`tooltip-mention-${indexKey}-${firstname}`"
      triggers="hover"
      variant="light"
      placement="bottomright"
      :no-fade="true"
    >
      <div v-for="user in adminMentionUserDetails" :key="user">
        {{ `${user.firstname} ${user.lastname}` }}
      </div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "ChatAdminProfile",

  props: {
    indexKey: {
      type: String,
      required: true
    },

    adminImageUrl: {
      type: String,
      required: false,
      default: null
    },
    firstname: {
      type: String,
      required: false
    },
    lastname: {
      type: String,
      required: false
    },
    totalAdminMention: {
      type: Number,
      required: false,
      default: 0
    },
    adminMentionUserDetails: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    primaryColor: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    formatName(val) {
      if (val) {
        val = val
          .trim()
          .substring(0, 1)
          .toUpperCase();
      }
      return val;
    }
  }
};
</script>

<style lang="scss">
.user_img_display {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ebedef;
}
.img-admin,
.img-admin-secondary {
  background-color: #aaa5e4;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(113, 96, 196, 0.27);
  font-size: 12px;
}
.img-admin-secondary {
  background-color: #4e6be3;
}

.img-admin-size {
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
}

.text-con-mode span {
  margin: auto 0;
  color: #fff;
  font-weight: bold;
}

.text-con-mode img {
  border-radius: 50%;
  height: 22px;
}
.mention-count {
  background-color: #fff !important;
  border-color: #832abf;
  width: 25px;
}
.mention-count span {
  color: #832abf !important;
  font-size: 10px;
}
</style>
