<template>
  <b-modal
    size="lg"
    hide-footer
    title="Agents"
    v-model="localShowModal"
    @hide="hide"
    ref="modal-agents"
    id="modal-agents"
  >
    <div class="text-center">
      <div class="form-group text-left">
        <label for="input-search-mention">Search Admin </label>

        <b-input-group>
          <b-form-input
            v-model="textSearch"
            id="input-search-mention"
            placeholder="Staff ID, Email"
            trim
            autocomplete="off"
          ></b-form-input>
          <template v-slot:append>
            <button class="btn bg-dark text-white pill-right">
              <font-awesome-icon :icon="['fas', 'search']" />
            </button>
          </template>
        </b-input-group>
      </div>
      <div class="panel-table">
        <b-table
          striped
          hover
          show-empty
          :fields="fields"
          :items="paginatedData"
          table-class="w-auto"
          empty-text="Empty Data"
          responsive
          class="table-main"
        >
          <template v-slot:cell(name)="data">
            <div class="text-left">
              <div class="name">
                {{ formatFullName(data.item) }}

                <b-badge
                  v-if="data.item.isAvailable"
                  pill
                  variant="online"
                  class="badge-status"
                  >Online</b-badge
                >
                <b-badge v-else pill variant="offline" class="badge-status"
                  >Offline</b-badge
                >
              </div>
              <p class="email">{{ data.item.email }}</p>
            </div>
          </template>
          <template v-slot:cell(team)="data">
            <div class="text-left">
              <div class="name">
                {{ formatTeamName(data.item.team) }}
              </div>
            </div>
          </template>
        </b-table>
      </div>
      <div class="d-flex justify-content-between align-items-center w-full">
        <p class="w-35">
          แสดงผล {{ (pagination.pageNo - 1) * pagination.perPage + 1 }} ถึง
          {{
            Math.min(
              pagination.pageNo * pagination.perPage,
              filteredData.length
            )
          }}
          จากทั้งหมด {{ filteredData.length }} รายการ
        </p>
        <div id="pagination" class="w-65 d-flex justify-content-end">
          <b-pagination
            class="w-auto"
            v-model="pagination.pageNo"
            :total-rows="filteredData.length"
            :per-page="pagination.perPage"
            @change="handlePagination"
          ></b-pagination>
          <b-form-select
            class="ml-md-3 w-auto"
            v-model="pagination.perPage"
            @change="handleChangePerPage"
            :options="pageOptions"
          ></b-form-select>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalAgents",
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
      required: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localShowModal: this.isShowModal, // สร้างตัวแปร local เก็บค่า prop
      fields: [
        { key: "name", label: "Name / Email", thStyle: { width: "60%" } },
        { key: "team", label: "Team", thStyle: { width: "40%" } }
      ],
      textSearch: "",
      pagination: {
        pageNo: 1,
        perPage: 10
      },
      pageOptions: [
        { value: 10, text: `10 / Page` },
        { value: 30, text: `30 / Page` },
        { value: 50, text: `50 / Page` },
        { value: 100, text: `100 / Page` }
      ]
    };
  },
  computed: {
    filteredData() {
      return this.items.filter(item => {
        return (
          item.firstname
            .toLowerCase()
            .includes(this.textSearch.toLowerCase()) ||
          item.email.toLowerCase().includes(this.textSearch.toLowerCase())
        );
      });
    },
    paginatedData() {
      const start = (this.pagination.pageNo - 1) * this.pagination.perPage;
      const end = start + this.pagination.perPage;
      return this.filteredData.slice(start, end);
    }
  },
  watch: {
    isShowModal(newVal) {
      this.localShowModal = newVal;
      this.textSearch = "";
    },
    localShowModal(newVal) {
      if (!newVal) {
        this.$emit("hide");
      }
    }
  },
  methods: {
    hide() {
      this.localShowModal = false;
    },
    formatFullName(val) {
      return `${val.firstname} ${val.lastname}`;
    },
    formatTeamName(val) {
      return val.map(item => item).join(", ");
    },
    handlePagination(page) {
      this.pagination.pageNo = page;
    },
    handleChangePerPage() {
      this.pagination.pageNo = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.email {
  color: #9c9c9c;
  font-size: 12px;
}
</style>
