<template>
  <div class="px-3">
    <div
      class="d-flex flex-row rounded-lg min-vh"
      v-if="!$store.state.isLoadingProfileDetail"
    >
      <div
        class="img_back mr-2"
        @click="$store.dispatch('setActivePanel', 2)"
        v-if="!isShowBoth"
      >
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="img_back_icon"
        />
      </div>
      <div class="section">
        <div class="user-img d-flex">
          <div
            v-if="imgUrlCurrentChat"
            class="rounded-circle user_img_display"
            v-bind:style="{
              backgroundImage: 'url(' + imgUrlCurrentChat + ')'
            }"
          ></div>
          <font-awesome-icon v-else :icon="['fas', 'user']" class="my-auto" />
        </div>
      </div>

      <div class="d-flex pl-2 w-100">
        <div class="d-flex flex-column w-100">
          <div :class="['d-flex justify-content-between']">
            <div class="d-flex section-profile">
              <span class="fs-16 name">{{ name }}</span>
              <div class="text-center" @click="editName()">
                <span class="icon-filter color-primary p-0 mx-2">
                  <font-awesome-icon :icon="['fas', 'pen']"
                /></span>
              </div>
            </div>

            <div class="d-flex">
              <b-dropdown
                id="dropdown-brand"
                variant="light"
                right
                class="p-0 "
                :class="[crmList.length > 1 ? 'active' : '']"
                :disabled="crmList.length == 0"
              >
                <template #button-content>
                  <div
                    class="d-flex justify-content-between align-items-center w-100"
                  >
                    <div class="mx-1 crm-name">
                      {{ select }}
                    </div>
                  </div>
                </template>
                <b-dropdown-item
                  v-for="(item, index) in crmList"
                  :key="index"
                  href="#"
                  @click="fetchAddress(item)"
                  :class="[item.first_name_th == select ? 'active' : '']"
                  >{{ item.first_name_th }}</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                variant="link"
                class="filter-dropdown-setting"
                toggle-class="text-decoration-none"
                no-caret
                right
              >
                <template #button-content>
                  <span class="cursor-pointer my-auto text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="20"
                      fill="currentColor"
                      class="bi bi-three-dots-vertical"
                      viewBox="0 0 15 20"
                    >
                      <path
                        d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                      />
                    </svg>
                  </span>
                </template>
                <b-dropdown-item href="#" @click="handleConnectCustomer(true)"
                  >Connnect</b-dropdown-item
                >
                <b-dropdown-item href="#" @click="handleConnectCustomer(false)"
                  >Disconnect</b-dropdown-item
                >
                <b-dropdown-item href="#" @click="handleCreateNewUserCrm"
                  >Create New</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
          <div
            class="d-flex mt-1"
            v-if="userDetail.user_tag && userDetail.user_tag.length > 0"
          >
            <div class="d-flex flex-wrap tag-list">
              <div
                class="tag mt-1 mr-2 px-1"
                v-for="(item, index) in userDetail.user_tag"
                :key="index + 'tag'"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="text-center" @click="show(false)">
              <span class="icon-filter color-primary p-0 ml-2">
                <font-awesome-icon :icon="['fas', 'pen']"
              /></span>
            </div>
          </div>
          <div class="d-flex mt-1" v-else>
            <b-button
              v-if="crmList.length > 0"
              variant="outline-primary"
              class="btn-transfer-agent px-2"
              @click="show(true)"
            >
              Add Tag
            </b-button>
          </div>
          <!-- <div class="d-flex mt-2">
            <b-form-checkbox
              v-model="$store.state.isChatTranslate"
              name="check-button-isTranslate"
              switch
              @change="handleTranslate"
            >
              แปลภาษา
            </b-form-checkbox>
          </div> -->
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center" v-else>
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <ModalEditName
      ref="modalEditName"
      :userChatInfo="userChatInfo"
      :displayName="displayName"
      :socialName="socialName"
      @edit-name="handleSubmit"
    />
    <ModalEditTag
      ref="modalEditTag"
      :userChatInfo="userChatInfo"
      :crmDetail="crmDetail"
      :tags="userDetail.user_tag"
      :textModal="textModal"
      @get-detail="$emit('get-detail', crmDetail)"
    />
    <ModalConnect
      ref="modalConnect"
      :chatRoomId="chatRoomId"
      :userChatInfo="userChatInfo"
      @selectedCustomer="$emit('get-detail', crmDetail)"
    />
    <ModalLoading ref="modalLoading" />
  </div>
</template>

<script>
import ModalEditTag from "../chatOrderProfile/modal/ModalEditTag.vue";
import ModalEditName from "../chatOrderProfile/modal/ModalEditName.vue";
import ModalConnect from "../chatOrderProfile/modal/ModalConnect.vue";
import ModalLoading from "./modal/ModalLoading.vue";
// import { required } from "vuelidate/lib/validators";

import { mapGetters } from "vuex";

export default {
  name: "ChatOrderProfileCard",
  components: {
    ModalEditTag,
    ModalConnect,
    ModalLoading,
    ModalEditName
  },
  props: {
    userInfo: {
      required: false,
      type: Object
    },
    userChatInfo: {
      required: false,
      type: Object
    },
    crmDetail: {
      required: false,
      type: Object
    },
    isShowDetail: {
      required: false,
      type: Boolean
    },
    isTranslate: {
      required: false,
      type: Boolean
    },
    userCustomField: {
      required: false,
      type: Array
    },
    crmList: {
      required: false,
      type: Array
    },
    userDetail: {
      required: false,
      type: Object
    },
    userTier: {
      required: false,
      type: Object
    },
    select: {
      required: false,
      type: String
    },
    isShowBoth: {
      required: false,
      type: Boolean
    },
    socialType: {
      required: false,
      type: Number
    }
  },
  computed: {
    ...mapGetters({
      selectedUserChatList: "getSelectedUserChatList",
      ticketFlagIsRegister: "getAgentTicketFlagRegister"
    }),
    chatRoomId() {
      let chatRoomId = this.selectedUserChatList.map(item => {
        return item.id;
      });
      return chatRoomId;
    },
    socialName() {
      let index = this.$store.getters.getChatSocialList.findIndex(
        t => t.id == this.socialType
      );
      if (index >= 0) {
        let socialName = this.$store.getters.getChatSocialList[index];
        return socialName.name;
      } else {
        return "";
      }
    }
  },
  watch: {},
  data() {
    return {
      imgUrlCurrentChat: "",
      nameCurrentChat: "",
      displayName: "",
      name: "",
      textModal: "",
      tag: [],
      timer: null
    };
  },
  // validations() {
  //   return {
  //     displayName: {
  //       required
  //     }
  //   };
  // },
  mounted() {
    this.imgUrlCurrentChat = this.$store.getters.getImgUrlCurrentChat;
    this.nameCurrentChat = this.$store.getters.getNameCurrentChat;
    this.displayName = this.$store.getters.getDisplayNameCurrentChat
      ? this.$store.getters.getDisplayNameCurrentChat
      : this.nameCurrentChat;
    this.name = this.displayName;
  },
  methods: {
    handleTranslate() {
      this.$store.dispatch("handleChangeChatTranslate", {
        userId: this.userChatInfo.id
      });
    },
    editName() {
      this.$refs.modalEditName.show();
    },
    fetchAddress(item) {
      this.$refs.modalLoading.show();
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.$emit("onchange-crm", item);
      this.$emit("get-detail", item);
      this.$emit("get-address", item);
      this.timer = setTimeout(() => {
        this.$refs.modalLoading.hide();
      }, 500);
    },
    async handleSubmit(value) {
      // this.$v.displayName.$touch();
      // if (this.$v.displayName.$error) return;
      this.displayName = value ? value : this.displayName;
      this.$refs.modalLoading.show();
      let body = {
        displayname: this.displayName,
        userID: this.userChatInfo.id
      };
      let response = await this.$axios.post(
        `${this.$chatBaseUrl}/user/UpdateDisplayName`,
        body
      );
      setTimeout(() => {
        this.$refs.modalLoading.hide();
      }, 1000);

      if (response.data.result) {
        this.$emit("get-chatlist");
        if (!this.displayName) {
          this.displayName = this.nameCurrentChat;
          this.name = this.nameCurrentChat;
          this.$store.commit("setDispalyNameCurrentChat", this.nameCurrentChat);
        } else {
          this.name = this.displayName;
          this.$store.commit("setDispalyNameCurrentChat", this.displayName);
        }
        this.$bvModal.hide("modal-edit-name");
        this.$store.commit("setMessageGlobalAlert", response.data.message);
        this.$store.commit("setShowGlobalAlert", true);
      } else {
        this.$store.commit("setMessageGlobalAlertError", response.data.message);
        this.$store.commit("setShowGlobalAlertError", true);
      }
    },
    async handleConnectCustomer(flag) {
      if (flag) {
        this.$refs.modalConnect.show();
      } else {
        this.$refs.modalLoading.show();
        let body = {
          crmUserId: this.crmDetail.user_guid,
          userID: this.userChatInfo.id
        };
        let response = await this.$axios.post(
          `${this.$chatBaseUrl}/crm/disconnect-customer`,
          body
        );
        setTimeout(() => {
          this.$refs.modalLoading.hide();
        }, 1000);

        if (response.data.result) {
          this.$emit("get-detail", this.crmDetail);
          this.$store.commit("setMessageGlobalAlert", "Disconnect Success");
          this.$store.commit("setShowGlobalAlert", true);
        } else {
          this.$store.commit(
            "setMessageGlobalAlertError",
            response.data.message
          );
          this.$store.commit("setShowGlobalAlertError", true);
        }
      }
      await this.$store.commit("UPDATE_AGENT_TICKET_URL_CREATE", "");
      if (this.ticketFlagIsRegister) {
        await this.$store.commit("UPDATE_AGENT_TICKET_FLAG_REGISTER", false);
      }
    },
    async handleCreateNewUserCrm() {
      this.$emit("show-create-new-crm");
    },
    show(flag) {
      this.textModal = flag ? "Add Tag" : "Edit Tag";
      this.$refs.modalEditTag.setTag();
      this.$refs.modalEditTag.show();
    },
    hide() {
      this.$refs.modalEditTag.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .filter-dropdown-setting button {
  color: #4f5d73;
}

.tag-list {
  word-break: break-word;
}

.tag-list::-webkit-scrollbar {
  display: none;
}

::v-deep .filter-dropdown-setting .dropdown-toggle::after {
  display: none;
}

::v-deep .dropdown-toggle::after {
  color: #4f5d73 !important;
}

.fs-16 {
  font-size: 16px;
}

::v-deep .dropdown-menu.show {
  padding: 0;
}

.tag {
  background-color: rgba(113, 96, 196, 0.27);
  border-radius: 2px;
  font-size: 12px;
  color: rgb(113, 96, 196);
  cursor: pointer;
}

.user-img {
  height: 50px;
  width: 50px;
}

.section {
  position: relative;
  height: 50px;
  width: 50px;
}

.user_img_display {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}

::v-deep .active > .btn,
::v-deep .active {
  color: #8f20c6 !important;
  background-color: #e2dff3 !important;
}

.icon-filter {
  cursor: pointer;
}

.min-vh {
  min-height: 75px;
}

.user-img svg {
  width: 80%;
  height: 80%;
}

.btn-transfer-agent {
  border-radius: 33px;
  padding: 0px 5px;
  font-size: 11px;
}

.name,
.crm-name {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.name {
  max-width: 95px;
}

.crm-name {
  max-width: 90px;
}

@media (min-width: 1600px) and (max-width: 1800px) {
  .name {
    max-width: 190px;
  }
}

@media (min-width: 1800px) {
  .name {
    max-width: 220px;
  }
}

::v-deep .dropdown-toggle {
  padding: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #832abf;
  background-color: #832abf;
}

.spinner-border {
  width: 20px;
  height: 20px;
}

::v-deep a:disabled {
  opacity: 0.5 !important;
  text-decoration: none !important;
}
.img_back {
  position: relative;
  height: 44px;
  width: auto;
}
.img_back_icon {
  height: 100%;
  width: 15px;
  // color: #f3591f;
}
@media (max-width: 767.98px) {
  .section {
    position: relative;
    height: 40px;
    width: 40px;
    .img_back {
      height: 30px;
    }
  }
  .user-img {
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 430px) {
  .name {
    max-width: 160px;
  }
}
</style>
