<template>
  <div class="chat-box-mention-item" @click="selectMention(staff)">
    <div
      v-if="staff.imageUrl"
      class="rounded-circle user_img_display img-admin-size mb-1"
      v-bind:style="{
        backgroundImage: 'url(' + staff.imageUrl + ')'
      }"
    ></div>
    <div
      v-else
      class="rounded-circle img-admin img-admin-size mb-1 mr-1 mt-1"
      v-bind:style="{
        backgroundColor: randomColor(),
        color: 'white'
      }"
    >
      <span>{{ formatName(staff.firstname) }}</span>
    </div>

    <p>{{ staff.firstname }} {{ staff.lastname }}</p>
    <p>{{ staff.email }}</p>
  </div>
</template>

<script>
export default {
  name: "ChatBoxMention",
  props: {
    staff: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  methods: {
    selectMention(val) {
      this.$emit("handleClickMention", val);
    },
    formatName(val) {
      if (val) {
        val = val
          .trim()
          .substring(0, 1)
          .toUpperCase();
      }
      return val;
    },
    randomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  }
};
</script>

<style lang="scss" scoped>
.chat-box-mention-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  padding: 5px 10px;

  p {
    margin: 0;
  }
  p:last-of-type {
    margin-left: auto;
  }
}
</style>
