<template>
  <b-badge
    pill
    class="badge-mode"
    :style="{ backgroundColor: color, color: 'white' }"
  >
    {{ time }} Due</b-badge
  >
</template>

<script>
export default {
  name: "ChatDueBox",
  props: {
    color: {
      required: true,
      type: String
    },
    time: {
      required: true,
      type: String
    }
  },
  data() {
    return {};
  },
  mounted() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.due-box {
  border-radius: 5px;
  color: black;
  padding: 2px 5px;
}
</style>
